import React from "react";
import { Link } from 'gatsby';

import "../scss/global.scss";
import SEO from "../components/seo";
import bgCurve from "../images/home/pg-404-logo.svg"
import bgLogo from "../images/logo-golden.svg"

const NotFoundPage = () => (
  <>

    <div className="content null">
      <span className="bg-curve"><img src={bgCurve} alt="bg-img" /></span>
      <div className="not-found-landing error-full-bg">
        <SEO title="404: Not found" />
        <div className="not-found-page">
          <div className="not-found-image"><img src={bgLogo} /></div>
          <h1 className="not-found-title">We are sorry, the page you were looking for cannot be found.</h1>
          <p>Please search again using different terms, or contact our office for more help.</p>

          <div class="not-found-block">
            <Link to="/" class="btn btn-primary"><span>Return Home</span></Link>
            <Link to="/contact-us" class="btn btn-number btn-outline"><span>Contact Us</span></Link>
          </div>
        </div>
      </div>
    </div>

  </>
)

export default NotFoundPage
